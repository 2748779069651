// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log("Vite ⚡️ Rails")

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   "Visit the guide for more information: ",
//   "https://vite-ruby.netlify.app/guide/rails"
// )

import * as Turbo from "@hotwired/turbo";
Turbo.start();

// import ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import { Application } from "@hotwired/stimulus";
const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

import "../stylesheets/application.scss";

import { registerControllers } from "../lib/stimulus-vite-helpers";
registerControllers(
  application,
  import.meta.glob("../../views/**/*_controller.ts", {
    eager: true,
  }),
);

import "chartkick/chart.js";

export { application };
