import { Controller } from "@hotwired/stimulus";
import "./page_map_controller.scss";

export default class extends Controller {
  static values = { rows: Number, cols: Number };
  static targets = ["grid", "cell", "cursor", "positionTop", "positionLeft"];

  positionTopTarget!: HTMLInputElement;
  positionLeftTarget!: HTMLInputElement;
  cursorTarget!: HTMLElement;
  cellTargets!: HTMLElement[];
  rowsValue!: number;
  colsValue!: number;

  position_top = 0;
  position_left = 0;

  connect() {
    this.position_top = +this.positionTopTarget.value || 1;
    this.position_left = +this.positionLeftTarget.value || 1;

    this.savePosition();
  }

  changeCursorLocation(e: Event) {
    const target = e.target as HTMLElement;
    const column = target.getAttribute("column");
    const row = target.getAttribute("row");

    if (!column || !row) {
      return;
    }

    const x = parseInt(column);
    const y = parseInt(row);

    this.position_top = y;
    this.position_left = x;

    this.savePosition();
  }

  moveUp() {
    if (this.position_top === 0) return;

    this.position_top -= 1;
    this.savePosition();
  }

  moveDown() {
    if (this.position_top >= this.rowsValue - 1) return;

    this.position_top += 1;
    this.savePosition();
  }

  moveLeft() {
    if (this.position_left === 0) return;

    this.position_left -= 1;
    this.savePosition();
  }

  moveRight() {
    if (this.position_left >= this.colsValue - 1) return;

    this.position_left += 1;
    this.savePosition();
  }

  savePosition() {
    // console.log(this.position_top, ":", this.position_left)

    /*
    if (this.position_top < 0) this.position_top = 0
    if (this.position_top >= this.rowsValue) this.position_top = this.rowsValue - 1

    if (this.position_left < 0) this.position_left = 0
    if (this.position_left >= this.colsValue) this.position_left = this.colsValue - 1
    */

    this.positionTopTarget.value = this.position_top.toString();
    this.positionLeftTarget.value = this.position_left.toString();

    this.cursorTarget.style.display = "block";

    const mapPositionTop = this.position_top - 1;
    const mapPositionLeft = this.position_left - 1;
    const cellIdx = this.colsValue * mapPositionTop + mapPositionLeft;

    this.cellTargets[cellIdx].append(this.cursorTarget);
  }
}
